var stickyHeader = true;
var countUpStats = true;
var countUpStatsDuration = 3000;

// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// see https://fresco-docs.netlify.com/#/carousel/background-video
// ---------------------------------------------------------------
// var carouselBackgroundVideoYouTubeID = '';

// var visualAppealsLoadMore = false;
// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// -----------------------------------------------------------------
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link'];
// var feedItemsCarouselBreakpoint = 576;

// see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// --------------------------------------------------------------------
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// Make sure to set a card layout prefab for:
// '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'
// --------------------------------------------------------------------------------
// var sidebarCards = false;

$(document).ready(function () {
  // Remove duplicates buttons on maincalltoaction
  // Get all the buttons in the "mainCallToActionButtons" container
  const buttonsContainer = document.querySelector(".mainCallToActionButtons");
  const buttons = buttonsContainer.querySelectorAll("a");
  // Create a Set to track unique hrefs
  const seen = new Set();
  // Loop through the buttons and remove duplicates
  buttons.forEach((button) => {
    if (seen.has(button.href)) {
      button.remove(); // Remove the button if the href is a duplicate
    } else {
      seen.add(button.href); // Add unique href to the set
    }
  });
  // Select all headers from FAQ and make them toggle
  const headers = document.querySelectorAll(".accordianHeader");
  headers.forEach((header) => {
    header.addEventListener("click", () => {
      header.classList.toggle("active");
    });
  });

  // Move sub banner descriptions out to below with the content
  adjustListingIntro();
  // Adjust breadcrumb with proper links
  adjustBreadcrumbs();

  // Move comments outside of post content
  if ($(".postContent .postComments").length) {
    $(".postContent .postComments").insertBefore(".contentBlockWrapper > .contentBlock .postAside");
  }
  // Move subsite title into image zone
  if ($(".headerWrapperSubsite").length) {
    $(".headerTextSubsite").appendTo(".headerWrapperSubsite");
  }
  // Check if the div is "empty" (ignoring whitespace) - hide if yes
  if ($(".headerText").children().length === 0 || $(".headerText").children().text().trim() === "") {
    $(".headerText").hide();
  }
  // Update text content of "Current appeals" tab
  if ($(".tabsWrapper .tabs .tab.cpt a").text().trim() === 'Current appeals') {
    $(".tabsWrapper .tabs .tab.cpt a").text('Appeals');
  }
  // Anchor offset adjustment
  $('a[href^="#"]').on('click', function (event) {
    // Prevent default behavior
    event.preventDefault(); 
    // Get the target ID
    const targetId = $(this).attr('href').substring(1); 
    const targetElement = $('#' + targetId);
    const offsetElement = $('#pageHeader').outerHeight();
    if (targetElement.length) {
      // Adjust offset as needed
      const offset = offsetElement + 20; 
      const targetPosition = targetElement.offset().top - offset;
      // Smooth scroll
      $('html, body').animate({ scrollTop: targetPosition }, 500); 
    }
  });

  // --------------------------------------------
  
  // Initiate Homepage Feed One
  initCarousel(
    "HomepageOneFeed", 4,
    "See all steps",
    "/Pages/"
  );
  // Initiate Homepage Feed Two
  initCarousel(
    "HomepageTwoFeed", 3,
    "See all courses",
    "/Pages/"
  );
  // Initiate Homepage Feed Three
  initCarousel(
    "HomepageThreeFeed", 4,
    "See all events",
    "/Pages/Events/"
  );
  // Initiate Homepage Feed Four
  initCarousel(
    "HomepageFourFeed", 3,
    "See all stories",
    "/Pages/"
  );
  // Initiate Homepage Feed Five
  initCarousel(
    "HomepageFiveFeed", 4,
    "See all teachers",
    "/Pages/"
  );
  // Initiate Homepage Feed Six
  initCarousel(
    "HomepageSixFeed", 4,
    "See all articles",
    "/Blogs/news-and-blog"
  );
  // Rename 'Read More' to 'Book'
  renameReadMore();
  // Remove linking for stories feed
  removeHomeStoriesLinks();
  // Initiate Footer logos section
  footerCarouselLogos("FooterLogos");
  // Append Footer boxes section
  appendFooterBoxes("FooterCredits", "FooterSocials", "FooterLinks");
  // Append Homepage Feed One to its own section
  appendFeedSection("HomepageOneFeed", "homefeaturecategory-homeboxfeedone");
  // Append Homepage Feed Two to its own section
  appendFeedSection("HomepageTwoFeed", "homefeaturecategory-homeboxfeedtwo");
  // Append Homepage Feed Three to its own section
  appendFeedSection("HomepageThreeFeed", "homefeaturecategory-homeboxfeedthree");
  // Append Homepage Feed Four to its own section
  appendFeedSection("HomepageFourFeed", "homefeaturecategory-homeboxfeedfour");
  // Append Homepage Feed Five to its own section
  appendFeedSection("HomepageFiveFeed", "homefeaturecategory-homeboxfeedfive");
  // Append Homepage Feed Six to its own section
  appendFeedSection("HomepageSixFeed", "homefeaturecategory-homeboxfeedsix");
  // Append CTA box + video
  appendCTASection("homefeaturecategory-videoctacontent", "homefeaturecategory-videoctaembed");
  // Append CTA Dual sections
  appendCTASection("homefeaturecategory-dualctaboxone", "homefeaturecategory-dualctaboxtwo");
  // Append Anchor
  appendAnchorSection("homefeaturecategory-homeboxanchors", "homeIntroWrapper");
});

function renameReadMore() {
  // For each home carousel pages
  $("body.homepage .HomepageTwoFeed .feedItem").each(
    function () {
      // Rename to 'Book'
      $(this).find(".readMore").html("Book");
    }
  );
}

function removeHomeStoriesLinks() {
  // For each home carousel pages
  $("body.homepage .HomepageFourFeed .feedItem").each(
    function () {
      // Remove any reference of linking to said page
      $(this).removeAttr("onclick");
      $(this).find("a").removeAttr("href").removeAttr("title");
    }
  );
}

function adjustBreadcrumbs() {
  // If exists already, skip function
  if ($('.breadcrumbWrapper .breadcrumbLink a[href="/"]').text().trim() == 'Home') {
    console.log('Breadcrumb home exists');
  } else {
    // Edit breadcrumb with home and other links
    if ($(".breadcrumbWrapper").length) {
      var breadcrumbList = $(".breadcrumbWrapper .breadcrumbList");
      var breadcrumbHome = $('<li class="breadcrumbItem breadcrumbHome"><a href="/">Home</a></li>');
      breadcrumbHome.prependTo(breadcrumbList);
    }
  }
}

function adjustListingIntro() {
  // Move listing intro description out to below with the content
  if ($(".listingIntro").length) {
    $(".listingIntro").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
    $(".listingIntro").prependTo(".appealListingPage .donorsListWrapper");
    $(".listingIntro").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
  }
}

function appendFooterBoxes(box1, box2, box3) {
  // Get both the sections
  let sectionBox1 = $(`.${box1}`);
  let sectionBox2 = $(`.${box2}`);
  let sectionBox3 = $(`.${box3}`);
  // Wrap the section with a div
  sectionBox1.wrapInner('<div class="firstBox"></div>');
  // Then move the sections inside the first section
  sectionBox2.appendTo(sectionBox1);
  sectionBox3.appendTo(sectionBox1);
  // Do a final wrap on both
  sectionBox1.wrapInner('<div class="boxWrapper"></div>');
}

function footerCarouselLogos(sectionLogos) {
  // Fetch the footer logos secton
  let footerLogos = $(`.${sectionLogos}`);
  // Wrap the section with a div
  footerLogos.wrapInner('<div class="titleWrapper"></div>');
  // Fetch the title and target slider
  let footerTitle = $(`.${sectionLogos} .titleWrapper`);
  let sliderLogos = footerTitle.find("> ul:first-of-type").addClass("logos__slider");
  // Move logos list out from the title wrapper
  sliderLogos.insertAfter(footerTitle);
  // Create the slider arrows + dots + cta
  let elements = createSliderElements('See all logos', '#');
  // Then append under the carousel
  footerLogos.append(elements.navigator);
  // Get the logos list and init slick slider
  $(`.logos__slider`).slick({
    dots: false,
    arrows: true,
    prevArrow: $(`.${sectionLogos} .prev-slide`),
    nextArrow: $(`.${sectionLogos} .next-slide`),
    fade: false,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }
    ],
  });
}

function appendAnchorSection(anchor, target) {
  // Get both the sections
  let sectionAnchor = $(`.${anchor}`);
  let sectionTarget = $(`.${target}`);
  // Add ID attribute to section
  sectionAnchor.attr("id", anchor);
  sectionTarget.attr("id", target);
  // Then move the section inside the first section
  sectionAnchor.insertBefore(sectionTarget);
}

function appendCTASection(cta1, cta2) {
  // Get both the sections
  let sectionCTA1 = $(`.${cta1}`);
  let sectionCTA2 = $(`.${cta2}`);
  // Add ID attribute to section
  sectionCTA1.attr("id", cta1);
  sectionCTA2.attr("id", cta2);
  // Wrap the section with a div
  sectionCTA1.wrapInner('<div class="dualctaboxone"></div>');
  // Then move the section inside the first section
  sectionCTA2.appendTo(sectionCTA1);
  // Do a final wrap on both
  sectionCTA1.wrapInner('<div class="dualctaWrapper"></div>');
}

function appendFeedSection(feed, container) {
  // Get the feed carousel and feed title and id target
  let carousel = $(`.homeFeed.${feed}`);
  let titleSection = $(`.${container}`);
  let ctaButton = carousel.find(".button-arrow");
  // Attach feed associate ID
  titleSection.attr("id", feed);
  // Wrap the title content with a div
  titleSection.wrapInner('<div class="labelWrapper"></div>');
  titleSection.wrapInner('<div class="titleWrapper"></div>');
  // Then move the carousel inside the title section
  carousel.appendTo(titleSection);
  // And move the button arrow outside
  ctaButton.appendTo(titleSection.find(".titleWrapper"));
}

function initCarousel(feed, slides, ctaText, ctaURL) {
  // Set the carousel selector
  let carousel = ".homeFeed." + feed;
  // If carousl exists on page
  if ($(`${carousel}`).length) {
    // Adjust the feed details first
    adjustFeedDetails(carousel);
    // Create the slider arrows + dots + cta
    let elements = createSliderElements(ctaText, ctaURL);
    // Then append under the carousel
    $(`${carousel}`).append(elements.navigator);
    // Init slick slider
    $(`${carousel} .feedList`).slick({
      dots: false,
      arrows: true,
      prevArrow: $(`${carousel} .prev-slide`),
      nextArrow: $(`${carousel} .next-slide`),
      fade: false,
      infinite: true,
      slidesToShow: slides,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: (slides > 3) ? 3 : slides,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: (slides > 2) ? 2 : slides,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1
          },
        }
      ],
    });
  }
}

function adjustFeedDetails(carousel) {
  // for each feed item present
  $(`${carousel} .feedItem`).each(
    function () {
      // Set reference to both footer and details wrapper of feed
      let footerWrapper = $(this).find(".postFooterWrapper");
      let detailsWrapper = $(this).find(".feedItemDetailsWrapper");
      // Move the footer wrapper to the details wrapper
      footerWrapper.prependTo(detailsWrapper);
    }
  );
}

function createSliderElements(ctaText, ctaURL) {
  let prevIcon = '<i class="fa fa-chevron-left" aria-hidden="true"></i>';
  let nextIcon = '<i class="fa fa-chevron-right" aria-hidden="true"></i>';
  let prevArrow = '<button type="button" aria-label="Previous slide" class="prev-slide">' + prevIcon +'</button>';
  let nextArrow = '<button type="button" aria-label="Next slide" class="next-slide">' + nextIcon + '</button>';
  let sliderNav = '<div class="slider-nav">' + prevArrow + nextArrow + "</div>";
  let sliderBox = '<div class="slider-box">' + sliderNav + "</div>";
  let ctaButton = '<a class="button-arrow" href="' + ctaURL + '" aria-label="' + ctaText + '">' + ctaText + "</a>";
  let navigator = '<div class="navigator">' + sliderBox + ctaButton + "</div>";
  return {
    prevArrow,
    nextArrow,
    sliderNav,
    sliderBox,
    navigator,
  };
}